<template>
  <v-row dense>
    <v-col cols="12" md="9">
      <DebouncedTextField
        :input="text => handleInput('ADD_SEARCH_ASSIGNMENT', text)"
        label="Search Assignment"
        :value="assignments.searchAssignment"
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-select
        v-model="value"
        class="header-fields"
        flat
        hide-details
        :items="gradeByChoices"
        label="Grade by"
        solo
        @change="handleRouteChange"
      />
    </v-col>
    <v-col md="6">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        item-text="name"
        item-value="uuid"
        :items="courseChoices"
        label="Course"
        solo
        :value="assignments.selectedCourse"
        @input="
          courseId => handleInput('ADD_ASSIGNMENT_SELECTED_COURSE', courseId)
        "
      />
    </v-col>
    <!-- <v-col md="6">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        item-text="name"
        item-value="uuid"
        :items="batchChoices"
        label="Batch"
        solo
        :value="assignments.selectedBatch"
        @input="
          batchId => handleInput('ADD_ASSIGNMENT_SELECTED_BATCH', batchId)
        "
      />
    </v-col> -->
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import DebouncedTextField from "@/components/shared/DebouncedTextField.vue"

export default {
  name: "ByAssignmentSearch",

  components: { DebouncedTextField },

  props: {
    reset: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    value: "by_assignment",
    gradeByChoices: [
      // {
      //   text: "By Batch",
      //   value: "by_batch",
      // },
      {
        text: "By Assignment",
        value: "by_assignment",
      },
      {
        text: "By Student",
        value: "by_student",
      },
    ],
  }),

  computed: {
    ...mapGetters([
      "assignments",
      "batchChoices",
      "courseChoices",
      "listLoading",
    ]),
  },

  methods: {
    ...mapActions(["getAssignments"]),

    //  Filter
    handleInput(request, value) {
      this.reset()
      this.$store.commit(request, value ? value : "")
      this.getAssignments()
    },

    handleRouteChange() {
      if (this.value === "by_batch") {
        this.$router.push({
          name: "Home",
        })
      } else if (this.value === "by_student") {
        this.$router.push({
          name: "Student",
        })
      } else if (this.value === "by_assignment") {
        this.$router.push({
          name: "Assignment",
        })
      }
    },
  },
}
</script>

<style lang="scss"></style>
