<template>
  <div>
    <div class="mb-8">
      <ByAssignmentSearch :reset="resetPage" />
    </div>

    <v-data-table
      :custom-sort="() => assignments.data.data"
      :headers="headers"
      hide-default-footer
      :items="assignments.data.data"
      :loading="listLoading"
      multi-sort
      :sort-by="assignments.sort.fields"
      :sort-desc="assignments.sort.desc"
      @update:options="handleSort"
    >
      <template v-slot:[`header.action`]>
        <span class="subtitle-2 lightCarbon--text">
          {{ totalEntries }}
        </span>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        {{ item.title | formatAssignmentTitle(item.short_title) }}
      </template>

      <template v-slot:[`item.courses`]="{ item }">
        {{ item.courses.map(({ name }) => name) | formatCourses }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          class="pr-0 pr-md-2"
          color="primary"
          small
          text
          :to="{
            name: 'ViewAssignment',
            params: {
              assignmentId: item.uuid,
            },
          }"
        >
          VIEW ASSIGNMENT
        </v-btn>
      </template>

      <template v-slot:footer>
        <Paginator
          :page="assignments.page"
          :total="assignments.data.total"
          @change="handlePagination"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ByAssignmentSearch from "@/components/dashboard/assignments/ByAssignmentSearch"
import Paginator from "@/components/shared/Paginator"
import filters from "@/utils/mixins/filters"

export default {
  name: "ByAssignment",

  components: { ByAssignmentSearch, Paginator },

  mixins: [filters],

  data: () => ({
    headers: [
      {
        text: "Assignment",
        sortable: true,
        value: "title",
      },
      { text: "Courses", value: "courses", sortable: false },
      {
        text: "Total Submissions",
        value: "total_submissions",
        sortable: false,
      },
      { text: "Pending Reviews", value: "pending_reviews", sortable: false },
      {
        text: "",
        value: "action",
        sortable: false,
        align: "end",
      },
    ],
  }),

  computed: {
    ...mapGetters(["assignments", "listLoading"]),

    totalEntries() {
      const { data, page } = this.assignments

      return `${page * 10 > data.total ? data.total : page * 10} of ${
        data.total
      } Entries`
    },
  },

  methods: {
    ...mapActions(["getAssignments"]),

    resetPage() {
      this.$store.commit("SET_ASSIGNMENT_PAGE", { page: 1 })
    },

    handleSort({ sortBy, sortDesc }) {
      this.resetPage()
      this.$store.commit("SET_ASSIGNMENT_SORTABLE_FIELDS", {
        fields: sortBy,
        desc: sortDesc,
      })
      this.getAssignments()
    },

    handlePagination(newPage) {
      this.$store.commit("SET_ASSIGNMENT_PAGE", { page: newPage })
      this.getAssignments()
    },
  },
}
</script>

<style lang="scss"></style>
